import React from 'react'

import imageBack  from '../images/footer-calculadoras.png'


const Footer = () => {
    return (
        <div id="footer" style={{
            position: 'fixed',
            padding: '15px 10px',
            bottom: 0,
            width: '100%',
            /* Height of the footer*/ 
            // height: '30px',
            // backgroundImage: `url(${imageBack})`,
            textAlign: 'center',
            color: '#253746',
            backgroundColor: '#d5dde1'
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover'
        }}>
            <p style={{margin: 0}}>© {new Date().getFullYear()} Bristol-Myers Squibb Company</p>
        </div>
    )
}

export default Footer